import TranslateController from 'controller/shared/TranslateController';
import React from 'react'
import LibraryCarousel from 'view/features/feature_home/components/LibraryCarousel';

function SlideShow() {

	const {_t} = TranslateController();


	return (
		<section id="home" className="p-relative border-0 bg-quaternary overflow-hidden m-0 p-0
		video overlay overlay-show overlay-op-8 m-0 slider-height"
        data-video-path="/assets/video/piano"
        data-plugin-video-background
        data-plugin-options="{'posterType': 'jpg', 'position': '50% 50%'}">
			
			<div className="custom-hero-heading">
				<strong>Academy</strong>
			</div>
			
			<div className="container p-relative z-index-3">
				{/* <div className="particles-wrapper z-index-3">
					<div id="particles-4">
					</div>
				</div> */}
				<div className="row min-vh-100 align-items-center small-no-items-center lan-rtl">
					<div className="col-lg-6 text-center text-lg-start slider-margin">
						<div className="overflow-hidden mb-1">
							<h2 className=" lan-text-right font-poppins sm-text-center font-weight-semi-bold  lan-text-right text-color-primary text-uppercase  text-slide-1  line-height-2 line-height-sm-7 lan-kfont mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="100">{_t("Welcome to Kurdish Talent Academy")}</h2>
						</div>
						<h1 className=" lan-text-right sm-text-center text-color-light font-weight-bold custom-hero-font-1 pb-2 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">{_t("Where Culture Thrives,")} <br/> {_t("Minds Flourish")}</h1>
						<div className="d-block  lan-text-right sm-text-center appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
							<a href="/courses" className="kfont btn btn-primary btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-3 text-color-light text-color-hover-dark ml-2">{_t("Our Courses")} <i className="fas fa-arrow-right rotate-l-45 ms-2"></i></a>
						</div>
					</div>
					<div className="col-lg-6 text-center text-lg-start sm-margin-library">
						<LibraryCarousel />
					</div>
				

				</div>
			</div>
			
		</section>
	)
}

export default SlideShow