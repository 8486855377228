import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CourseHttpService } from '../../../services/course';
import InstructorAbout from '../../components/course/instructor/InstructorAbout';
import InstructorCourses from '../../components/course/instructor/InstructorCourses';
import InstructorSummary from '../../components/course/instructor/InstructorSummary';
import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import PageHeader from 'view/components/shared/PageHeader';
import NotationsGrid from 'view/components/course/components/NotationsGrid';
import PodcastsGrid from 'view/components/course/components/PodcastsGrid';
import TranslateController from 'controller/shared/TranslateController';
import InstructorPosts from 'view/features/feature_blog/components/blog_styles/InstructorPosts';
import SocialShareButtons from 'view/features/feature_blog/components/SocialShareButtons';
import { Utils } from 'utils';

function InstructorDetails({ artist }) {
	const { id, slug } = useParams();
	const { _t } = TranslateController();
	const [data, setData] = useState(artist || null);

	useEffect(() => {
		const handleContextmenu = e => {
		  e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		return function cleanup() {
		  document.removeEventListener('contextmenu', handleContextmenu)
		}
	  }, [])

	// Use artist if provided, otherwise fetch the data
	const { isLoading, error } = useQuery(['instructor', id, slug], () => {
		if (id) {
			return CourseHttpService.loadInstructor(id);
		} else {
			return CourseHttpService.loadInstructorBySlug(slug);
		}
	}, {
		enabled: !artist, // Disable query if artist is provided
		onSuccess: (fetchedData) => {
			setData(fetchedData);
			// Perform any action on success
		}
	});

	useEffect(() => {
		if (artist) {
			setData(artist);
		}
	}, [artist]);

	// if (isLoading) {
	// 	return <div>Loading...</div>;
	// }

	// // Handle any errors
	// if (error) {
	// 	return <div>Error loading instructor details</div>;
	// }

	return (
		<div className="body">
			<Header />

			<div role="main" className="main">
				<PageHeader child={<InstructorSummary instructor={data} />} showbreadcrumbs={false} />

				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container unselectable custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
								<div className="row lan-rtl">
									<div className="col-md-8">
										<InstructorAbout instructor={data} />
									</div>
									<div className="col-md-4 border-start">
										{/* <h4 className='kfont lan-rtl lan-text-right pt-3'>{_t('Articles')}</h4> */}
										{(data && data.count_of_posts > 0) && <InstructorPosts authorId={data.id} />}
									</div>
								</div>

							<InstructorCourses instructor={data} />

							{data && data.report.podcasts_count > 0 && (
								<h2 className='kfont lan-rtl lan-text-right'>{_t('Podcasts')}</h2>
							)}
							{(data && data.report.podcasts_count > 0) && <PodcastsGrid className={"col-6 col-md-4"} artistId={data.id} />}

							

							{data && data.report.notations_count > 0 && (
								<h2 className='kfont lan-rtl lan-text-right '>{_t('Notations')}</h2>
							)}

							{data && <NotationsGrid loadMore={true} scrollTop={0} peopleId={data.id} />}

							<div className="post-block mt-2 post-share lan-rtl">
								<h4 className="mb-3">{_t("Share this Post")}</h4>

								<SocialShareButtons title={data && Utils.getDefaultName(data)} url={`https://kurdishtalent.academy/${slug ? slug : `instructor/${data && data.id}`}?lan=${Utils.getCurrentLanguageName()}`} />
							</div>
						</div>
					</div>


				</div>


			</div>

			<Footer />
		</div>
	);
}

export default InstructorDetails;
