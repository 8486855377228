import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Utils, translate } from '../../../../utils'
import TranslateController from 'controller/shared/TranslateController';

function CourseCard({ course, showDescription = true }) {
    const { _t } = TranslateController()



    return (
        <div className='mb-4'>
            {/* <Link to={`/course/${course.id}`}> */}
                <span className="thumb-info thumb-info-swap-content anim-hover-inner-wrapper">
                    <span className="thumb-info-wrapper overlay overlay-show overlay-gradient-bottom-content">
                        <img src={course.image} className="img-fluid" alt={Utils.getDefaultName(course)} />
                        <span className="thumb-info-title thumb-info-title-vert-align bg-transparent w-100 text-center mw-100 p-0">
                            <span className="anim-hover-inner-translate-top-40px hide-on-hover transition-1s d-inline-block ">
                                <span className="thumb-info-inner text-4-5 kfont course-card-title">{Utils.getDefaultName(course)}</span>
                            </span>

                        </span>


                        <span className="thumb-info-title bottom-20 bg-transparent w-100 mw-100 p-0 text-center">
                            <span className="thumb-info-swap-content-wrapper">
                                <span className="thumb-info-inner">
                                    <a href={`/course/${course.id}`} className="btn btn-primary btn-circle"><i className="fas fa-arrow-right"></i></a>
                                </span>
                                <span className="thumb-info-inner text-2 lan-rtl sm-mb-5">
                                    {showDescription && (
                                        <div className="px-5 text-2 opacity-7 font-weight-medium text-light mb-2 p-mb-2 kfont line-height-15">{Utils.parseShortDescription(course)}</div>
                                    )}
                                    <p className='text-2 font-weight-light text-light mb-2'>{_t("Level")+" : "}{Utils.getDefaultName(course.level)}</p>
                                    <a href={`/course/${course.id}`} className="btn btn-modern btn-course-link btn-primary  btn-arrow-effect-1 py-2 px-3 font-weight-normal  kfont">{_t("View course")} <i className="fas fa-arrow-right ms-2"></i></a>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            {/* </Link> */}
        </div>

    )
}

export default CourseCard