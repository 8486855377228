import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query';
import { Utils } from 'utils'
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import TranslateController from 'controller/shared/TranslateController';

function HomeAbout() {
    const [settings, setData] = useState(false);
   
    const { isLoading, error: queryError, data, refetch } = useQuery('settings', () => { return SettingsHttpService.loadSettings(1) }, {
        onSuccess: (data) => {
            setData(data)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 100000,
    });

    const { _t } = TranslateController()


    return (
        <section id="concept">
            <div className="container bg-color-light p-relative z-index-2 py-1 mt-3">
                <div className="row py-2 pb-5">
                    <div className="col-lg-6 text-center">

                        <div className="d-flex mb-3">
                            <div className="p-2 flex-fill">
                                <div className="custom-el-3 bg-color-tertiary custom-border-radius-1 mx-auto appear-animation" data-appear-animation="zoomIn" data-appear-animation-delay="100">
                                    <div className="custom-el-3-img">
                                        <img className="p-relative" src="/assets/images/1.jpg" loading="lazy" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 flex-fill">
                                <div className="custom-el-3 bg-color-primary custom-border-radius-2 mx-auto appear-animation" data-appear-animation="zoomIn" data-appear-animation-delay="200">
                                    <div className="custom-el-3-img">
                                        <img className="p-relative" src="/assets/images/2.jpg" loading="lazy" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 flex-fill">
                                <div className="custom-el-3 custom-el-3-pos-1 bg-color-dark custom-border-radius-3 mx-auto appear-animation" data-appear-animation="zoomIn" data-appear-animation-delay="300">
                                    <div className="custom-el-3-img">
                                        <img className="p-relative" src="/assets/images/3.jpg" loading="lazy" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-3 custom-el-4-pos">
                            <div className="p-2 flex-fill">
                                <div className="custom-el-3 custom-el-3-pos-2 bg-color-dark custom-border-radius-4 ms-auto appear-animation" data-appear-animation="zoomIn" data-appear-animation-delay="400">
                                    <div className="custom-el-3-img">
                                        <img className="p-relative" src="/assets/images/4.jpg" loading="lazy" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 flex-fill">
                                <div className="custom-el-3 bg-color-tertiary custom-border-radius-5 me-auto appear-animation" data-appear-animation="zoomIn" data-appear-animation-delay="500">
                                    <div className="custom-el-3-img">
                                        <img className="p-relative" src="/assets/images/5.jpg" loading="lazy" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">

                        <h3 className="lan-text-right  text-8 text-xl-9 line-height-3 text-transform-none mb-2 mb-lg-3 mb-xl-4 mt-3 appear-animation kfont" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">{Utils.getDefaultName(settings)}</h3>
                        <div className="lan-rtl text-3-4 line-height-9 li-mb text-dark pb-1 mb-4 mb-lg-2 mb-xl-4 p-sm-2 appear-animation about-description" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">
                                {Utils.parseShortDescription(settings)}
                        </div>


                        <div className="d-block appear-animation pt-1 text-small-center" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
                            <a href="/about" className="btn btn-modern btn-primary text-capitalize text-3 px-2 py-1 anim-hover-translate-top-5px transition-2ms kfont"> {_t("Learn more")} <i className="fas fa-arrow-right rotate-l-45 ms-2"></i></a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeAbout