import React from 'react'
import InstrumentList from './instrument/InstrumentList'
import CMSLayout from 'view/cms/components/shared/CMSLayout'

function InstrumentPage() {
    return (
        <CMSLayout>
            <InstrumentList />
        </CMSLayout>
    )
}

export default InstrumentPage 