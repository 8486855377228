import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { HttpService } from 'services/http';
import { Utils } from 'utils';

function MusiciansGridHome({ query, size = 4 }) {
    const [instructors, setInstructors] = useState(null);

    const { isLoading, error, data, refetch, isFetching } = useQuery(
        ['musicians',],
        () => CourseHttpService.searchInstructors(query, 'musician', '', 1, 6),
        {
            onSuccess: (data) => {
                setInstructors(data.results);
            },
            // Optional: Adjust cache settings as needed
            // cacheTime: 60 * 60 * 24 * 30 * 1000, // 30 days
            refetchInterval: HttpService.DefaultRefetchInterval,
        }
    );

    return (
        <section id="concept">
            <div className="container bg-color-light p-relative z-index-2 py-1 mt-3">
                <div className="row py-2 pb-4">
                    <div className="col-lg-12 px-lg-5">
                        <div className="row portfolio-list sort-destination lan-rtl" >
                            {instructors?.map((instructor, index) => {

                                return (
                                    <div key={instructor.id + "ngi"} className="col-6 col-md-4 col-lg-2 " >
                                        <div className="portfolio-item mb-1">
                                            <Link to={instructor.slug ? `/${instructor.slug}` : `/instructor/${instructor.id}`}>
                                                <span className="thumb-info thumb-info-lighten border-radius-0 kfont lan-rtl">
                                                    <span className="thumb-info-wrapper border-radius-0">
                                                        <img src={Utils.getNotationImage(instructor)} className="img-fluid border-radius-0 notation-grid-img" alt="" />
                                                        <span className="thumb-info-title">
                                                            <span className="thumb-info-inner font-weight-light">{Utils.getDefaultName(instructor)}</span>
                                                        </span>
                                                        <span className="thumb-info-action">
                                                            <span className="thumb-info-action-icon bg-dark opacity-8"><i className="fas fa-eye"></i></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MusiciansGridHome;
