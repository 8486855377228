import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import CarouselLibraryItem from './CarouselLibraryItem';

function LibraryCarousel() {

    const libraryItems = [
       
        {
            name: 'Courses',
            image: '/assets/images/library/courses.jpg',
            url: '/courses/'
        },
        {
            name: 'Notations',
            image: '/assets/images/library/notations.jpg',
            url: '/notations/'
        },
        {
            name: 'Musicians',
            image: '/assets/images/library/musicians.jpg',
            url: '/musicians/'
        },
        // {
        //     name: 'Articles',
        //     image: '/assets/images/library/articles.jpg',
        //     url: '/articles/'
        // },
        // {
        // 	name: 'Publications',
        // 	image: '/assets/images/library/publications.jpg',
        // 	url: '/publications/'
        // },
        // {
        // 	name: 'Books',
        // 	image: '/assets/images/library/books.jpg',
        // 	url: '/books/'
        // },
        {
            name: 'Podcasts',
            image: '/assets/images/library/podcasts.jpg',
            url: '/podcasts/'
        },
        
        {
            name: 'Dictionary',
            image: '/assets/images/library/dictionary.jpg',
            url: '/dictionary/'
        }
        // Add more items as needed
    ];

    var settings = {
        dots: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };


    return (
        <div className='carousel-container opacity-9 library-slider ' >
            <Slider    {...settings}
            >

                {libraryItems.map((item, index) => (
                    <div key={`lin_item_${item.url}`} class=" p-1 pb-2">
                        <CarouselLibraryItem key={index} item={item} showTitle={false} />
                    </div>
                )
                )}
            </Slider>

        </div>
    )
}

export default LibraryCarousel