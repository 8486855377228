import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { Utils } from '../../../utils'
import CourseMenu from '../../components/course/components/CourseMenu'
import CourseReviews from '../../components/course/review/CourseReviews'
import CourseSummary from '../../components/course/components/CourseSummary'
import CourseSyllabus from '../../components/course/components/CourseSyllabus'
import CourseInstructors from '../../components/course/instructor/CourseInstructors'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import CourseAbout from '../../components/course/components/CourseAbout'
import PageHeader from 'view/components/shared/PageHeader'
import SocialShareButtons from 'view/features/feature_blog/components/SocialShareButtons'

function CourseDetails() {
	const { id } = useParams();


	const { isLoading, error, data } = useQuery(['course', id], () => { return CourseHttpService.loadCourse(id) }, {
		onSuccess: (course) => {
			// Utils.scrollTo(0)
		}
	});





	return (
		<div className="body">
			<Header />

			<div role="main" className="main">
				<PageHeader title={data && Utils.getDefaultName(data)} showbreadcrumbs={false} child={<CourseSummary course={data} />} />
				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-12 custom-padding-bottom-1 px-lg-5 bg-light">
							{/* <CourseMenu course={data} /> */}
							<div className="row lan-rtl">
								<div className="col-md-7">
									<CourseAbout course={data} />
								</div>
								<div className="col-md-5">
									{/* <hr className=' my-0' /> */}
									<CourseInstructors course={data} />
								</div>
							</div>
							<hr className='  my-0' />

							<CourseSyllabus course={data} />
							<SocialShareButtons showCopy={true} title={data && Utils.getDefaultName(data)} url={`https://kurdishtalent.academy/course/${data && data.id}?lan=${Utils.getCurrentLanguageName()}`} />
							<CourseReviews course={data} />


						</div>
					</div>
				</div>

				<div className="container">
					<div className="row pb-4 mb-4 full-width ">
						<div className="col-md-12 mx-md-auto p-0">

						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default CourseDetails