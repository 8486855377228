import TranslateController from 'controller/shared/TranslateController';
import React from 'react'


function CarouselLibraryItem({ item, showTitle = true }) {
	const { _t } = TranslateController();

	return (
		<div className='mb-4'>
			<span className="thumb-info thumb-info-swap-content anim-hover-inner-wrapper">
				<a href={`${item.url}`} className="overlay overlay-show overlay-gradient-bottom-content">
					<img src={item.image} className="img-fluid" alt={_t(item.name)} />
				</a>
			</span>
		</div>
	);
}
export default CarouselLibraryItem