import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import Loading from "view/cms/components/shared/Loading";
import { CourseHttpService } from "services/course";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";
import ImageFieldWithGallery from "view/cms/components/Widgets/shared/ImageFieldWithGallery";
import SelectInstrument from "view/cms/components/forms/course/selects/SelectInstrument";

function NotationPageForm({ id, notationId, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  
  
  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      CourseHttpService.loadNotePage(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    if(item.time){
      setValue("time",item.time/1000)
    }
    
    setNames(item.names)
    setRefresh(refresh+1)

    setImage(item.image)

    if(item.instrument){
      setSelectedInstrument(item.instrument.id)
    }
  }

  const handleInstrumentSelect = (value, name) => {
    setSelectedInstrument(value)
    setValue(name, value)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "names": names,
      "time": data.time * 1000,
      "image" : item_image,
      "notation": notationId,
      "instrument": selectedInstrument
    }

    setDone(false)
    setLoading(true)
    CourseHttpService.saveNotePage(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  

  return (
    <div>
      

      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <TextField
                fullWidth
                label="Start Time in seconds"
                value={watch('time') ?? "1"}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("time")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <SelectInstrument 
                callback={handleInstrumentSelect}
                value={selectedInstrument}
                placeholder="Select Instrument"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <ImageFieldWithGallery key={item_image+"itimg"} defaultImageUrl={item_image} onImageSelected={setImage} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Page"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default NotationPageForm;
