import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import { Link } from 'react-router-dom';
import { HttpService } from 'services/http';

function InstructorsGrid({ query, searchQuery = "", type = "instructor",size=30 }) {
    const [pageNumber, setPageNumber] = useState(1);
    const [instructors, setInstructors] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const { isLoading, error, data, refetch, isFetching } = useQuery(
        ['search_instructors', type, query, searchQuery, pageNumber],
        () => CourseHttpService.searchInstructors(query, type, searchQuery, pageNumber,size),
        {
            onSuccess: (data) => {
                if (pageNumber === 1) {
                    setInstructors(data.results);
                } else {
                    setInstructors(prev => [...prev, ...data.results]);
                }
                // Assuming 'results' is empty when no more data is available
                setHasMore(data.results.length==30);
            },
            // Optional: Adjust cache settings as needed
            // cacheTime: 60 * 60 * 24 * 30 * 1000, // 30 days
            refetchInterval: HttpService.DefaultRefetchInterval,
        }
    );

    useEffect(() => {
        setPageNumber(1);
        refetch();
    }, [query, searchQuery, refetch]);

    const loadMore = () => {
        if (hasMore && !isFetching) {
            setPageNumber(prev => prev + 1);
        }
    };

    return (
        <div className='mt-1'>
            <div className="row py-3 row-cols-md-6 ">
                {instructors.map((instructor, i) => (
                    <div
                        className="col-6 mb-4 mb-md-3"
                        data-appear-animation="fadeIn"
                        data-appear-animation-delay={i * 250}
                        key={"ins" + instructor.id}
                    >
                        <Link to={instructor.slug ? `/${instructor.slug}` : `/instructor/${instructor.id}`}>
                            <div className="instructor-grid-item text-center">
                                <img
                                    className="img-fluid img-sm-85p mb-2"
                                    src={Utils.getArtistImage(instructor)}
                                    alt={Utils.getDefaultName(instructor)}
                                />
                                <h3 className="kfont text-color-dark text-2 mb-0">
                                    {Utils.getDefaultName(instructor)}
                                </h3>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {/* Loading Indicator */}
            {isLoading && instructors.length === 0 && <div>Loading...</div>}

            {/* Error Message */}
            {error && <div>Error loading instructors.</div>}

            {/* Load More Button */}
            {hasMore && (
                <div className="text-center my-4">
                    <button
                        onClick={loadMore}
                        disabled={isFetching}
                        className="btn btn-primary"
                    >
                        {isFetching ? 'Loading...' : 'Load More'}
                    </button>
                </div>
            )}
        </div>
    );
}

export default InstructorsGrid;
