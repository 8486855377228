import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { HttpService } from 'services/http';
import { Utils } from 'utils';
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

var courseIds;

const ProgressTodayTable = () => {
    // 1. Create a piece of state to store the selected date.
    //    Default is today.
    const [selectedDate, setSelectedDate] = useState(
        new Date().toISOString().split('T')[0] // yyyy-MM-dd
    );

    const [overallStats, setOverallStats] = useState({
        totalEnrollment: 0,
        totalCompletion: 0,
        averageProgress: 0,
    });

    const navigate = useNavigate(); // Initialize the useNavigate hook

    // 2. Modify your query to depend on 'selectedDate'.
    //    Pass 'selectedDate' to your API call so the backend can filter by date if needed.
    const {
        isLoading,
        error,
        data,
        refetch
    } = useQuery(['course-statistics', selectedDate], () => {
        // If your backend expects the date as a query parameter or path parameter,
        // you'd modify this accordingly. For example:
        return CourseHttpService.loadCourseStatistics(selectedDate);
    }, {
        onSuccess: (data) => {
            const courseNames = data.map((item) => Utils.getDefaultName(item.course));
            courseIds = data.map((item) => item.course.id);
            const enrollmentCounts = data.map((item) => item.enrollment_count);
            const completionCounts = data.map((item) => item.completion_count);
            const averageProgress = data.map((item) => item.average_progress);

            // Calculate overall stats
            let totalEnrollment = enrollmentCounts.reduce((a, b) => a + b, 0);
            let totalCompletion = completionCounts.reduce((a, b) => a + b, 0);
            let averageProgressTotal =
                averageProgress.reduce((a, b) => a + b, 0) / averageProgress.length;

            if (data && data.length > 0) {
                totalEnrollment += data[0].plus.enrollment_count;
                totalCompletion += data[0].plus.completion_count;
                averageProgressTotal += data[0].plus.average_progress;
            }

            setOverallStats({
                totalEnrollment,
                totalCompletion,
                averageProgress: averageProgressTotal,
            });
        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    // 3. Handler for when the date changes. We simply update our state
    //    and let react-query automatically refetch.
    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };



    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <div>
            <input
                type="date"
                className='form-control '
                id="date-select"
                value={selectedDate}
                onChange={handleDateChange}
            />

            {isLoading ? (<div><LinearProgress /></div>) : (
                <table className="table mb-3">
                    <tbody>
                        <tr>
                            <th>Total Enrollment</th>
                            <td>{Utils.numberFormat(overallStats.totalEnrollment)}</td>
                        </tr>
                        <tr>
                            <th>Total Completion</th>
                            <td>{Utils.numberFormat(overallStats.totalCompletion)}</td>
                        </tr>
                        <tr>
                            <th>Total Progress</th>
                            <td>
                                {Utils.numberFormat(overallStats.averageProgress.toFixed(2))} %
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}


        </div>
    );
};

export default ProgressTodayTable;
