import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course'; // Implement loadCourseStatistics to accept start/end
import { HttpService } from 'services/http';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import LoadingInside from '../../shared/LoadingInside';
import { LinearProgress } from '@mui/material';

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const ProgressTodayChart = () => {
  // 1) Default start/end date to the current month
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const [startDate, setStartDate] = useState(
    firstDayOfMonth.toISOString().split('T')[0] // e.g. "2025-02-01"
  );
  const [endDate, setEndDate] = useState(
    now.toISOString().split('T')[0]             // e.g. "2025-02-03"
  );

  // 2) Fetch data based on start/end date
  const { data, isLoading, error } = useQuery(
    ['course-stats-range', startDate, endDate],
    () => CourseHttpService.loadEnrollmentByDateStatistics(null, startDate, endDate),
    {
      refetchInterval: HttpService.DefaultRefetchInterval, // optional auto-refetch
    }
  );

  if (error) {
    return <div>Error loading chart data</div>;
  }

  // 3) Prepare the arrays for each metric
  //    Example of each item in `data`: 
  //      { day: "2025-02-01", enrollment_count: 10, completion_count: 3, average_progress: 45.0 }
  const chartLabels = data?.map(item => item.day.slice(0, 10)) || [];
  const enrollmentData = data?.map(item => item.enrollment_count) || [];
  const completionData = data?.map(item => item.completion_count) || [];
  const averageProgressData = data?.map(item => item.average_progress) || [];

  // 4) Create separate data objects for each chart
  const enrollmentChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Enrollment Count',
        data: enrollmentData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0,0,255,0.05)',
        fill: true,
        tension: 0.1,
      }
    ]
  };

  const completionChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Completion Count',
        data: completionData,
        borderColor: 'green',
        backgroundColor: 'rgba(0,128,0,0.05)',
        fill: true,
        tension: 0.1,
      }
    ]
  };

  const averageProgressChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Average Progress',
        data: averageProgressData,
        borderColor: 'orange',
        backgroundColor: 'rgba(255,165,0,0.05)',
        fill: true,
        tension: 0.1,
      }
    ]
  };

  // 5) Chart options (shared by all three charts)
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false, // We'll rely on individual headings instead
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        // If your strings are already "YYYY-MM-DD", you can just show them as is.
        // If you want to forcibly format them, you could add a 'ticks.callback' here.
        ticks: {
          display:false,
          callback: (value, index, ticks) => {
            // 'chartLabels' should be the array you used in `labels: chartLabels`
            // If it's already "YYYY-MM-DD", you can simply return it,
            // or slice it if the string is longer.
            const dateString = chartLabels[index];
            // Example: if you need just first 10 chars
            return dateString.slice(0, 10); // "2025-02-01"
          },
        },
      }
    }
  };

  // 6) Handlers for date changes
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // 7) Render 3 separate charts
  return (
    <div>
      <div className="mb-3 d-flex">
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className="form-control me-3"
        />
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className='form-control '
        />
      </div>

      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <div className="mb-4">
            {/* <h4>Enrollment Count</h4> */}
            <Line data={enrollmentChartData} options={chartOptions} />
          </div>

          {/* <div className="mb-4"> */}
            {/* <h4>Completion Count</h4> */}
            {/* <Line data={completionChartData} options={chartOptions} /> */}
          {/* </div> */}

          <div>
            {/* <h4>Average Progress</h4> */}
            <Line data={averageProgressChartData} options={chartOptions} />
          </div>
        </>
      )}


    </div>
  );
};

export default ProgressTodayChart;
