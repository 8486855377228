import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { BaseHttpService } from "services/base";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";
import ImageFieldWithGallery from "view/cms/components/Widgets/shared/ImageFieldWithGallery";

function CountryForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);

  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadCountry(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)
    setImage(item.image)

    setNamesRefresh(refresh + 1)
  }

  const saveChanges = (data) => {
    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "names": names,
      "code": data.code
    }

    setDone(false)
    setLoading(true)
    BaseHttpService.saveCountry(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      setError("Something went wrong")
    });
  };

  return (
    <div>
      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group mb-4">
              <TextField
                fullWidth
                label="Country Code"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("code", { required: true })}
                error={errors.code}
                helperText={errors.code ? "Country code is required" : ""}
              />
            </div>
            <div className="form-group">
              <NamesWidgets key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
            <hr className="solid" />
          </div>
          {/* <div className="col-md-4">
            <div className="form-group">
              <ImageFieldWithGallery type="country" key={item_image + "itimg"} defaultImageUrl={item_image} onImageSelected={setImage} />
            </div>
          </div> */}
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Country"}
          </Button>
        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default CountryForm; 