import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogStyle1({ categoryId, query, artistId,exclude_category}) {


    const [posts, setPosts] = useState(null);



    const { isLoading, error, data, refetch } = useQuery(['posts_style1', categoryId, artistId], () => { return PostHttpService.loadPosts(categoryId, query,null,null,'',exclude_category) }, {
        onSuccess: (data) => {
            setPosts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 10000,
    });

    useEffect(() => {
        refetch()
    }, [query])




    return (
        <div className="row lan-rtl">

            <div className="col-lg-7 mb-3 mb-md-2 ">
                {posts && posts.map((post, index) => {
                    if (index === 0)
                        return (
                            <a key={`post_bs1_${post.id}`} href={`/post/${post.id}/${post.slug}`}>
                                <article className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                    <div className="thumb-info-wrapper thumb-info-wrapper-opacity-2">
                                        <img src={post.image} className="img-fluid" alt={post.title} />
                                        <div className="thumb-info-title  bg-title-fade  p-4">
                                            <Link to={`/blog/${post.category.id}`} className="thumb-info-type  bg-color-primary px-2 mb-1 kfont text-light font-14">
                                                {Utils.getDefaultName(post.category)}
                                            </Link>
                                            <div className="thumb-info-inner mt-1">
                                                <h2 className="font-weight-bold text-color-light line-height-2 text-5 text-sm-4 letter-spacing-normal mb-0">{post.title}</h2>
                                            </div>
                                            <div className="thumb-info-show-more-content">
                                                <div className="mb-0 text-1 line-height-9 mb-1 mt-2 text-light opacity-5 lan-kfont">{Utils.parse(post.short_description, 30)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        )
                }
                )}
            </div>


            <div className="col-lg-5 ">
                {posts && posts.map((post, index) => {
                    if (index > 0 && index < 4)
                        return (
                            <a key={`post_bs1_${post.id}`} href={Utils.getPostUrl(post)}>
                                <article key={`bs1_post_${post.id}`} className="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-1 mb-0 mb-md-2">
                                    <div className="row align-items-center pb-1">
                                        <div className="col-md-5">
                                            <Link to={Utils.getPostUrl(post)}>
                                                <img src={post.image} className="img-fluid border-radius-0" alt={post.title} />
                                            </Link>
                                        </div>
                                        <div className="col-md-7 ps-sm-1 mb-sm-2 ">
                                            <div className="thumb-info-caption-text  hide-on-small">
                                                <div className="thumb-info-type text-light text-uppercase d-inline-block bg-color-primary px-2 m-0 mb-1 float-none">
                                                    <Link to={`/articles/${post.category.id}`} className="text-decoration-none text-color-light text-light kfont font-14">{Utils.getDefaultName(post.category)}</Link>
                                                </div>
                                                <h2 className="d-block line-height-2 text-4 text-dark font-weight-bold mt-1 mb-0">
                                                    <Link to={`/post/${post.id}/${post.slug}`} className="text-decoration-none text-color-dark text-color-hover-primary">{post.title}</Link>
                                                </h2>
                                            </div>
                                            <div className="thumb-info-title bg-title-fade full-width p-4 show-on-small">
                                                <Link to={`/blog/${post.category.id}`} className="thumb-info-type  bg-color-primary px-2 mb-1 kfont float-none text-light">
                                                    {Utils.getDefaultName(post.category)}
                                                </Link>
                                                <div className="thumb-info-inner mt-1">
                                                    <h2 className="font-weight-bold text-color-light line-height-2 text-5 text-sm-4 letter-spacing-normal  mb-0">{post.title}</h2>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {index < 3 && (<hr className={`my-2-5 solid-gray-1 hide-on-small `} />)}
                                </article>
                            </a>
                        )
                }
                )}
            </div>
        </div>
    )
}

export default BlogStyle1