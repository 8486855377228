import React from 'react'
import CmsHeader from '../../components/shared/CmsHeader'
import AdminView, { EditorView } from 'view/cms/components/shared/Permissions'
import PermissionCheck from 'view/cms/components/shared/PermissionCheck'
import SettingItem from 'view/cms/components/shared/SettingItem'

function Settings() {
  return (
    <div className="body">
      <CmsHeader />

      <div role="main" className="main">
        <div className="container">
          <div className="box-header mb-5">
            <h5>Settings</h5>
          </div>
          <PermissionCheck permission="setting" showError={1}>
            <div className="row">
              <AdminView>
                <SettingItem to="/cms/settings/general" icon="cog" title="General" />
                <SettingItem to="/cms/settings/countries" icon="globe" title="Countries" />
                <SettingItem to="/cms/settings/languages" icon="language" title="Languages" />
                <SettingItem to="/cms/settings/dictionary" icon="book" title="Dictionary" />
                <SettingItem to="/cms/about" icon="info" title="About Items" />
                <SettingItem to="/cms/settings/faq" icon="question" title="FAQ" />
                <SettingItem to="/cms/media" icon="image" title="Media" />
                <SettingItem to="/cms/translations" icon="globe" title="Translations" />
              </AdminView>
              <AdminView>
                <SettingItem to="/cms/users" icon="user" title="Users" />
                <SettingItem to="/cms/roles" icon="tasks" title="Roles" />
                <SettingItem to="/cms/instructor" icon="user" title="People" />
                <SettingItem to="/cms/support" icon="envelope" title="Support Messages" />
              </AdminView>
              <EditorView>
                <SettingItem to="/cms/dictionary" icon="list" title="Dictionary" />
              </EditorView>
            </div>
          </PermissionCheck>
        </div>
      </div>
    </div>
  )
}

export default Settings