import React from 'react';
import { Link } from 'react-router-dom';

const shortcuts = [
  {
    name: 'People',
    url: '/cms/instructor/',
  },
  {
    name: 'Notations',
    url: '/cms/notation',
  },
  {
    name: 'Articles',
    url: '/cms/posts',
  },
  {
    name: 'Translations',
    url: '/cms/translations',
  },
  {
    name: 'Support',
    url: '/cms/support',
  },
  
  // Add more shortcut objects as needed
];

function DashboardShortcuts() {
  return (
    <div className='dashobard-shortcuts'>
      {shortcuts.map((shortcut, index) => (
        <Link
          key={index}
          to={shortcut.url}
          rel="noopener noreferrer"
          className="shortcut d-flex flex-column align-items-center text-decoration-none text-2-5"
        >
          <span className="shortcut-name">{shortcut.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default DashboardShortcuts;
