import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BaseHttpService } from 'services/base';
import { Utils } from 'utils';
import CMSLayout from 'view/cms/components/shared/CMSLayout';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';
import SearchQuery from 'view/cms/components/forms/shared/SearchQuery';
import { Avatar } from '@mui/material';
import CountryForm from '../components/CountryForm';

function CountriesPage() {
  const [countries, setData] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState("");
  const [count, setCount] = useState();

  const { isLoading, error, data, refetch } = useQuery(['countries'], () => BaseHttpService.loadCountries(), {
    onSuccess: (data) => {
      setData(data.results)
      setCount(data.count)
    },
    refetchInterval: 60 * 10000,
  });

  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const _handleEdit = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setEditModalIsOpen(true)
  }

  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = countries.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  return (
    <CMSLayout>
      <div className='box'>
        <div className="box-header mb-5">
          <h5>Countries ({count})</h5>
          <div className="btns d-flex">
            <SearchQuery onQuery={(query) => { setQuery(query); refetch(); }} query={query} />
            <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
          </div>
        </div>
        <div className="box-body general-list">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Name</th>
                <th className='kfont'>ناو</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {countries && countries.map((item, index) => (
                <tr key={"countrylist" + item.id+index}>
                  <td>{index + 1}</td>
                  <td><Link onClick={(e) => { _handleEdit(e, item.id) }}>{item.image && (<Avatar alt={Utils.getDefaultName(item)} src={item.image} />)}</Link></td>
                  <td><Link onClick={(e) => { _handleEdit(e, item.id) }}>{Utils.getNameByLanguage(3, item)}</Link></td>
                  <td className='kfont'><Link onClick={(e) => { _handleEdit(e, item.id) }}>{Utils.getNameByLanguage(3, item)}</Link></td>
                  <td className='text-right'>
                    <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                      <span><i className="fas fa-trash color-gray "></i></span>
                      <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
          open={editModalIsOpen}>
          <CountryForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />
        </CustomModal>

        <DeleteDialog
          url={`/base/country/${selectedId}`}
          isOpen={deleteDialogIsOpen}
          key={"mdl" + deleteDialogIsOpen + selectedId}
          setOpen={setDeleteDialogIsOpen}
          notifyDone={_onDeleteDone}
        />
      </div>
    </CMSLayout>
  )
}

export default CountriesPage