import React from 'react'
import { Link } from 'react-router-dom'

function SettingItem({ to, icon, title }) {
    return (
        <div className="col-6 col-sm-4 col-lg-2">
            <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                <div className="featured-box featured-box-no-borders featured-box-box-shadow">
                    <Link to={to} className="text-decoration-none">
                        <span className="box-content px-1 py-4 text-center d-block">
                            <span className="text-primary text-8 position-relative top-3 mt-3">
                                <i className={`fas fa-${icon}`}></i>
                            </span>
                            <span className="elements-list-shadow-icon text-default">
                                <i className={`fas fa-${icon}`}></i>
                            </span>
                            <span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
                                {title}
                            </span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SettingItem 