import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { HttpService } from 'services/http';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { Utils, translate } from '../../../utils'
import LoginModal from '../user/LoginModal';
import { UserHttpService } from 'services/user';
import { Avatar } from '@mui/material';
import HeaderCategories from './HeaderCategories';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import { useQuery } from 'react-query';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function Header({ transparent = false, dark = 0 }) {

	const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
	const [profile, setProfile] = useState(false);
	const localStorageInfo = HttpService.getUser()

	const [settings, setSettings] = useState(false);
	const [courses, setCourses] = useState(false);
	const [categories, setCategories] = useState(null);


	const { isLoading, error: queryError, data, refetch } = useQuery('settings', () => { return SettingsHttpService.loadSettings(1) }, {
		onSuccess: (data) => {
			setSettings(data)
		},
		// cacheTime: 60*60*24*30*1000// Cache results for 30 days
		refetchInterval: HttpService.DefaultRefetchInterval,
	});

	const { data : cats } = useQuery('menu_categories', () => { return PostHttpService.loadCategories() }, {
        onSuccess: (data) => {
            setCategories(BuildCategories(data))
        },
        refetchInterval: 60 * 10000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });

    const BuildCategories = (categories) => {

        if (categories != null) {

            var parents = [];

            parents = categories?.filter(x => x.menu && x.status);

            parents.forEach(parent => {
                parent.childs = Utils.getChilds(categories, parent.id, 1)
            })

            return parents;
        }

    }


	const { changeLanguage, translations, language } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)

	}

	const [hidden, setHidden] = useState(false);
	const [atTop, setAtTop] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const currentScroll = window.scrollY;
			const headerHeight = 0; // Replace with your header height

			setHidden(currentScroll > headerHeight && currentScroll > lastScroll);
			setAtTop(currentScroll < 10);
			lastScroll = currentScroll;
		};

		if (localStorageInfo) {
			_loadUserProfile();
		}

		let lastScroll = 0;
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	const _loadUserProfile = () => {
		UserHttpService.loadProfile().then(response => {
			setProfile(response);
		})
	}

	const LiteratureMenuItem = () => {

		const [open, setOpen] = useState(false)


		if(language !== "so") return;

		return (
			<li className={`hide-on-small dropdown ${open ? "open" : ""} `}>
				<Link to="/literature" className="dropdown-item kfont">
					{_t("Literature")}
				</Link>
			</li>
		)
	}

	const MobileLiteratureMenuItem = () => {

		const [open, setOpen] = useState(false)

		return (
			<li className={`show-on-small dropdown ${open ? "open" : ""} `}>
				<a className="nav-link dropdown-item dropdown-toggle kfont" href="/literature">
					{_t("Literature")}
					<i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i>
				</a>
				<ul className="dropdown-menu">
				{categories && categories.map(category => (
					<li className="kfont"><Link to={`/literature/${category.id}`} className="dropdown-item" >{Utils.getDefaultName(category)}</Link></li>
				))}
				</ul>
				{/* <HeaderCategories /> */}
			</li>
		)
	}


	const AboutMenuItem = () => {
		const [open, setOpen] = useState(false)

		return (
			<li className={`dropdown ${open ? "open" : ""} `}>
				<Link to="/about" className="dropdown-item kfont">
					{_t("About")}
					{/* <i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i> */}
				</Link>
				{/* <ul className="dropdown-menu kfont">
					<li className="kfont">
						<Link className="dropdown-item kfont" to="/about" >
							{_t("Kurdish Academy")}
						</Link>
					</li>
					<li className="kfont">
						<Link className="dropdown-item kfont" to="/faq" >
							{_t("FAQ")}
						</Link>
					</li>
				</ul> */}
			</li>
		)
	}


	

	const AcademyMenuItem = () => {

		const [open, setOpen] = useState(false)

		return (
			<li className={`dropdown ${open ? "open" : ""} `}>
				<a className="nav-link dropdown-item dropdown-toggle kfont" href="/library">
					{_t("Academy")}
					<i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i>
				</a>
				<ul className="dropdown-menu">
					<li className="kfont"><Link className="dropdown-item" to="/courses" >{_t("Courses")}</Link></li>
					<li className="kfont"><Link className="dropdown-item" to="/notations" >{_t("Notations")}</Link></li>
					<li className="kfont"><Link className="dropdown-item" to="/musicians" >{_t("Musicians")}</Link></li>
					<li className="kfont"><Link className="dropdown-item" to="/articles" >{_t("Articles")}</Link></li>
					{/* <li className="kfont"><Link className="dropdown-item" to="/publications" >{_t("Publications")}</Link></li> */}
					{/* <li className="kfont"><Link className="dropdown-item" to="/books" >{_t("Books")}</Link></li> */}
					<li className="kfont"><Link className="dropdown-item" to="/podcasts" >{_t("Podcasts")}</Link></li>
					<li className="kfont"><Link className="dropdown-item" to="/dictionary" >{_t("Dictionary")}</Link></li>
				</ul>
				{/* <HeaderCategories /> */}
			</li>
		)
	}

	const UserMenuItem = () => {

		const [open, setOpen] = useState(false)

		if ((localStorageInfo != null)) {
			return (
				<li className={`dropdown ${open ? "open" : ""} `}>
					<Link className="dropdown-item dropdown-toggle ">
						<i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i>
						{profile ? (
							<div className='d-flex align-items-center'>
								<Avatar className='mr-2' alt={profile.first_name} src={Utils.getProfileImage(profile)} />
								<div className="show-on-small mx-2">
									{profile.first_name}
								</div>
							</div>
						)

							: <>Welcome</>}
					</Link>
					<ul className="dropdown-menu">
						<li className="kfont">
							<a className="dropdown-item " href="/home" >
								{_t("My Courses")}
							</a>
						</li>
						<li className="kfont">
							<a className="dropdown-item " href="/profile" >
								{_t("Profile")}
							</a>
						</li>
						<li className="kfont">
							<a className="dropdown-item " href="/support" >
								{_t("Support")}
							</a>
						</li>
						<li className="kfont">
							<a className="dropdown-item " href="/?signout" >
								{_t("Sign out")}
							</a>
						</li>
					</ul>
				</li>
			)
		}
		else return (
			(!localStorageInfo) && (
				<li className="dropdown kfont">
					<Link className="dropdown-item " onClick={() => { setLoginModalIsOpen(true) }} >
						{_t("Sign Up")}
					</Link>
				</li>
			)
		)
	}

	const CoursesMenuItem = () => {

		const [open, setOpen] = useState(false)

		return (
			<li className={`dropdown ${open ? "open" : ""} `}>
				<Link to="/courses" className="dropdown-item kfont">
					{_t("Courses")}
				</Link>

			</li>
		)

		// return (
		// 	<li className={`dropdown ${open ? "open" : ""} `}>
		// 		<a className="nav-link dropdown-item dropdown-toggle kfont" href="/courses">
		// 			{_t("Academy")}
		// 			<i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i>
		// 		</a>
		// 		<ul className="dropdown-menu kfont">
		// 			<li className="kfont">
		// 				<Link className="dropdown-item kfont" to="/courses" >
		// 					{_t("Courses")}
		// 				</Link>
		// 			</li>
		// 			<li className="kfont">
		// 				<Link className="dropdown-item kfont" to="/instructors" >
		// 					{_t("Instructors")}
		// 				</Link>
		// 			</li>
		// 			<li className="kfont">
		// 				<Link className="dropdown-item kfont" to="/dictionary" >
		// 					{_t("Dictionary")}
		// 				</Link>
		// 			</li>
		// 		</ul>
		// 	</li>
		// )
	}


	return (
		<header id="header" className={` ${hidden ? 'hidden-header' : ''}  header-transparent`} data-plugin-options="{'stickyScrollUp': true, 'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 1, 'stickyHeaderContainerHeight': 72}">
			<div className={`header-body border-top-0 box-shadow-none h-auto ${atTop ? 'transparenti' : 'bg-quaternary'} ${dark ? '' : ''} `} style={{ top: "0px" }}>
				<div className="header-container container p-static">
					<div className="header-row ">
						<div className="header-column">
							<div className="header-row">
								<div className="header-logo">
									<a href="/">
										<img alt="Porto" width="150" src="/assets/images/logo.png" />
									</a>
								</div>
							</div>
						</div>
						<div className="header-column justify-content-end">
							<div className="header-row">
								<div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
									<div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-text-capitalize header-nav-main-arrows header-nav-main-full-width-mega-menu header-nav-main-mega-menu-bg-hover header-nav-main-mega-menu-bg-hover-dark header-nav-main-effect-5">
										<nav className="collapse">
											<ul className="nav nav-pills" id="mainNav">
												{/* <li>
													<a className="nav-link  kfont" href="/">{_t("Home")}</a>
												</li> */}

												<AboutMenuItem />
												
												{/* <CoursesMenuItem /> */}

												<AcademyMenuItem />
												<LiteratureMenuItem />
												<MobileLiteratureMenuItem />
												
												<UserMenuItem />

												{/* <li className="dropdown kfont">
													<Link to="/" className="dropdown-item " >
														<i className="fa fa-home"></i>
													</Link>
												</li> */}


												<li className="dropdown hide-on-small">
													<Link className="dropdown-item dropdown-toggle lan-kfont">
														{/* {Utils.getCurrentLanguageNameFull()} */}
														<i className="fa fa-globe font-size-20 opacity-7"></i> &nbsp;
													</Link>
													<ul className="dropdown-menu">
														<li onClick={(e) => { e.preventDefault(); changeLanguage("so") }} className="kfont"><Link className="dropdown-item" >کوردی</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("kr") }} ><Link className="dropdown-item" >Kurdî</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("en") }} ><Link className="dropdown-item" >English</Link></li>
													</ul>
												</li>

												<li className='show-on-small border-bottom-0 mt-3'>
													<ul className="language-mobile-menu">
														<li onClick={(e) => { e.preventDefault(); changeLanguage("so") }} className="kfont"><Link className="dropdown-item" to={`/?lan=so`}>کوردی</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("kr") }} className="kfont"><Link className="dropdown-item" to={`/?lan=kr`}>Kurdi</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("en") }} ><Link className="dropdown-item" to={`/?lan=en`}>English</Link></li>
													</ul>
												</li>

												<ul className="social-icons social-icons-clean social-icons-medium mb-4 show-on-small-flex">
													<li className="social-icons-facebook">
														<a href={settings ? settings.facebook : ""} target="_blank" title="Facebook">
															<i className="fab fa-facebook-f text-color-light"></i>
														</a>
													</li>
													<li className="social-icons-twitter">
														<a href={settings ? settings.twitter : ""} target="_blank" title="Twitter">
															<i className="fab fa-twitter text-color-light"></i>
														</a>
													</li>
													<li className="social-icons-instagram">
														<a href={settings ? settings.instagram : ""} target="_blank" title="Instagram">
															<i className="fab fa-instagram text-color-light"></i>
														</a>
													</li>
													<li className="social-icons-linkedin">
														<a href={settings ? settings.spotify : ""} target="_blank" title="Spotify">
															<i className="fab fa-spotify text-color-light"></i>
														</a>
													</li>
												</ul>

												<li >
													<Link to="/explore" className="dropdown-item hide-on-small ">
														<i className="fa fa-search"></i>
													</Link>

												</li>

											</ul>

										</nav>
									</div>
									{/* <a className="btn btn-primary font-weight-semibold text-3 py-lg-3 btn-gradient text-quaternary anim-hover-translate-top-5px transition-2ms ms-4" href="demo-creative-agency-2-contact-us.html" target="_blank"><span className="px-lg-4 d-block ws-nowrap">Contact Us</span></a> */}
									<a href="/explore" className="btn text-light show-on-small" >
										<i className="fas fa-search"></i>
									</a>
									<button className="btn header-btn-collapse-nav text-dark" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
										<i className="fas fa-bars"></i>
									</button>


								</div>
							</div>
						</div>
						<LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} isOpen={loginModalIsOpen} />
					</div>
				</div>
			</div>
		</header>

	)
}

export default Header