import { Utils } from "utils";
import { HttpService } from "../http";


export class CourseHttpService {

    static async loadCertificate(uid) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/certificate/${uid}`, null);
        return response;
    }

    static async saveCourseCategorySortOrder(body) {

        var service = new HttpService();
        var response;

        response = await service.post(`/academy/course/category/sort/`, body);

        return await response
    }

    static async saveCourseCategory(body) {

        console.log('/academy/course/category/')

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/category/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/category/', body);
        

        return await response
    }

    
    static async loadCourseCategory(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/category/${id}`, null);
        return response;
    }

    static async loadCourseCategories() {
        var service = new HttpService();
        var response = await service.get(`/academy/course/category`, null);
        return response;
    }

    static async saveTimeSignature(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/notation/time-signature/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/notation/time-signature/', body);
        

        return await response
    }

    static async loadTimeSignature(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/time-signature/${id}`, null);
        return response;
    }

    static async loadTimeSignatures() {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/time-signature/`, null);
        return response;
    }

    static async saveScale(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/notation/scale/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/notation/scale/', body);
        

        return await response
    }

    static async loadScale(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/scale/${id}`, null);
        return response;
    }

    static async loadScales() {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/scale/`, null);
        return response;
    }

    static async setWriterToNotation(notation,instructor,action="add") {

        var service = new HttpService();
        var response;
        let body = {
            action:action
        }

        response = await service.post(`/academy/course/notation/${notation}/writer/${instructor}/`, body);

        return response
    }
    
    static async setArtistToNotation(notation,instructor,action="add") {

        var service = new HttpService();
        var response;
        let body = {
            action:action
        }

        response = await service.post(`/academy/course/notation/${notation}/artist/${instructor}/`, body);

        console.log(response);

        return response
    }

    static async updateNotePageSquarePosition(id,body) {

        var service = new HttpService();
        var response;

        if (id) {
            response = await service.put(`/academy/course/notation/notepage/notesquare/${id}`, body);
        }
        

        return await response
    }

    static async saveNotePageSquare(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/notation/notepage/notesquare/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/notation/notepage/notesquare/', body);
        

        return await response
    }
    
    static async loadNotePageSquare(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/notepage/notesquare/${id}`, null);
        return response;
    }

    static async loadNotePageSquares(notePageId) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/notepage/notesquare/?size=1000&note_page=${notePageId}`, null);
        return response;
    }

    static async saveNotePage(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/notation/notepage/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/notation/notepage/', body);
        

        return await response
    }
    
    static async loadNotePage(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/notepage/${id}`, null);
        return response;
    }

    static async loadNotePages(notationId) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/notepage/?notation=${notationId}`, null);
        return response;
    }

    static async saveNotation(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/notation/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/notation/', body);
        

        return await response
    }
    
    static async loadNotation(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/notation/${id}`, null);
        return response;
    }

    static async searchNotations(query = "",pageNumber=1,size=30,artist,peopleId) {
        var service = new HttpService();


        if(query){
            query+= `&page=${pageNumber}`
        }
        else{
            query=`?page=${pageNumber}`;
        }

        if(size){
            query+= `&size=${size}`
        }

        if(artist){
            query+= `&artists=${artist}`
        }

        if(peopleId){
            query+= `&people=${peopleId}`
        }

        let url = `/academy/course/notation/${query}`;

        // console.log(url);

        var response = await service.get(url, null);
        return response;
    }

    static async searchNotationArtists(searchQuery = "",pageNumber=1,size=30) {
        var service = new HttpService();

        var query = `?size=${size}`

        if(searchQuery){
            query+= `&query=${searchQuery}`
        }

        if(pageNumber){
            query+= `&page=${pageNumber}`
        }

        let url = `/academy/course/notation/artists/${query}`;
        console.log(url)

        var response = await service.get(url, null);
        return response;
    }

    static async loadNotations(query = "", size = 100, excludeId = 0,pageNumber = 1) {
        var service = new HttpService();
        let url = `/academy/course/notation/?query=${encodeURIComponent(query)}&size=${size}&page=${pageNumber}`;
    
        if (excludeId > 0) {
            url += `&exclude_id=${encodeURIComponent(excludeId)}`;
        }
    
        // if (levels.length > 0) {
        //     const levelsParam = levels.join(',');
        //     url += `&levels=${encodeURIComponent(levelsParam)}`;
        // }
    
        var response = await service.get(url, null);
        return response;
    }

    static async SetInstructorToPodcast(podcast,instructor,action="add") {

        var service = new HttpService();
        var response;
        let body = {
            action:action
        }

        response = await service.post(`/academy/course/podcast/${podcast}/instructor/${instructor}/`, body);

        return response
    }

    
    static async updateEpisodeCount(episodeId) {

        var service = new HttpService();
        var response;

        let body = {
        }

        response = await service.post(`/academy/course/podcastepisode/addcount/${episodeId}`, body);

        return await response
    }
    static async savePodcastEpisode(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/podcastepisode/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/podcastepisode/', body);

        return await response
    }

    static async loadPodcastEpisode(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/podcastepisode/${id}`, null);
        return response;
    }

    
    static async loadPodcastEpisodes(podcast) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/podcastepisode/?podcast=${podcast}`, null);
        return response;
    }
    

    static async savePodcast(body) {

        var service = new HttpService();
        var response;

        // console.log(body)

        if (body.id) {
            response = await service.put(`/academy/course/podcast/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/podcast/', body);
        

        return await response
    }
    
    static async loadPodcast(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/podcast/${id}`, null);
        return response;
    }

    static async loadPodcastsBySize(size = 3) {
        var service = new HttpService();
        let url = `/academy/course/podcast/?size=${size}`;
        var response = await service.get(url, null);
        return response;
    }

    static async searchPodcasts(query = "") {
        var service = new HttpService();
        let url = `/academy/course/podcast/${query}`;
    
        var response = await service.get(url, null);
        return response;
    }

    static async loadPodcasts(query = "", artistId) {
        var service = new HttpService();
        let url = `/academy/course/podcast/?query=${encodeURIComponent(query)}`;
    
        if (artistId) {
            url += `&instructor=${artistId}`;
        }

        
        var response = await service.get(url, null);
        return response;
    }

    static async loadCountryStatistics() {
        var service = new HttpService();
        var response = await service.get('/academy/course/statistics_country/', null);
        return response;
    }

    static async loadGenderStatistics() {
        var service = new HttpService();
        var response = await service.get('/academy/course/statistics_gender/', null);
        return response;
    }
    

    static async loadCourseStatistics(date=null) {
        var service = new HttpService();

        let query = "?id>0";
        if(date){
            query += `&date=${date}`;
        }

        var response = await service.get(`/academy/course/course-statistics/${query}`, null);


        return response;
    }
    static async loadEnrollmentByDateStatistics(date=null,start_date=null,end_date=null) {
        var service = new HttpService();

        let query = "?id>0";
        if(date){
            query += `&date=${date}`;
        }
        if(start_date){
            query += `&start_date=${start_date}`;
        }
        if(end_date){
            query += `&end_date=${end_date}`;
        }


        var response = await service.get(`/academy/course/date-enrollment-statistics/${query}`, null);

        console.log(query);
        console.log(response);

        return response;
    }
    
    static async saveLevel(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/level/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/level/', body);

        return await response
    }

    static async loadLevel(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/level/${id}`, null);
        return response;
    }


    static async loadLevels() {
        var service = new HttpService();
        var response = await service.get('/academy/course/level/', null);
        return response;
    }

    static async loadInstruments() {
        var service = new HttpService();
        var response = await service.get('/academy/course/instrument/', null);
        return response;
    }

    static async loadInstrument(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/instrument/${id}`, null);
        return response;
    }

    static async saveInstrument(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/instrument/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/instrument/', body);

        return await response
    }

    static async loadCourses(query = "", categories = [], levels = []) {
        var service = new HttpService();
        let url = `/academy/course/?query=${encodeURIComponent(query)}`;
    
        if (categories.length > 0) {
            const categoriesParam = categories.join(',');
            url += `&categories=${encodeURIComponent(categoriesParam)}`;
        }
    
        if (levels.length > 0) {
            const levelsParam = levels.join(',');
            url += `&levels=${encodeURIComponent(levelsParam)}`;
        }
    
        var response = await service.get(url, null);
        return response;
    }
    
    static async loadCoursesBySize(size = 5) {
        var service = new HttpService();
        let url = `/academy/course/?size=${size}`;
    
        var response = await service.get(url, null);
        return response;
    }

    static async loadEnrolledGenders(course_id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/enrollment/genders/?course=${course_id}`, null);
        return response;
    }

    static async loadCoursesBySize(size=30) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/?size=${size}`, null);

        return response;
    }

    static async loadRecentlyViewedCourses() {
        var service = new HttpService();
        var response = await service.get('/academy/course/', null);
        return response;
    }

    static async loadCourse(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/${id}`, null);
        return response;
    }

    static async saveCourse(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/', body);
        

        return await response
    }


    static async loadLectures(course) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/lecture/?course=${course}`, null);
        return response;
    }
    

    static async loadLecture(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/lecture/${id}`, null);
        return response;
    }


    static async saveLecture(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/lecture/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/lecture/', body);

        return await response
    }

    static async loadLessons(lecture,content=null) {

        var content_query = ``;
        if(content){
            content_query = `&content=${content}`
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/lesson/?lecture=${lecture}${content_query}`, null);

        return response;
    }

    static async loadLesson(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/lesson/${id}`, null);
        return response;
    }


    static async saveLesson(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/lesson/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/lesson/', body);

        return await response
    }

    static async loadContents(lesson) {

        var service = new HttpService();
        var response = await service.get(`/academy/course/content/?lesson=${lesson}`, null);
        return response;
    }

    static async loadContent(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/content/${id}`, null);
        return response;
    }


    static async saveContent(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/content/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/content/', body);

        return await response
    }

    static async logContent(body) {

        var service = new HttpService();
        var response;
        response = await service.post('/academy/course/content/log/', body);
        return await response
    }

    static async loadInstructorsForPodcast({exclude_podcast = null,type = "instructor"}) {
        let query = `?1`

        if(exclude_podcast){
            query += `&exclude_podcast=${exclude_podcast}`
        }

        var languageId = Utils.getCurrentLanguageId();


        query += `&type=${type}`
        query += `&language=${languageId}`

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/${query}`, null);
        return response;
    }
    

    static async loadInstructors(course=null,podcast=null,exclude_course = null,type = "instructor") {
        let query = `?1`

        if(exclude_course){
            query += `&exclude_course=${exclude_course}`
        }

        var languageId = Utils.getCurrentLanguageId();

        query += `&type=${type}`
        query += `&language=${languageId}`

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/${query}`, null);
        return response;
    }

    static async searchInstructorsBySearchQuery(query="") {
        // console.log(`/academy/course/instructor/?query=${query}`)

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/?id>0&${query}`, null);
        return response;
    }

    static async searchInstructors(query="",type="",searchQuery="",pageNumber=1,size=30) {
        // console.log(`/academy/course/instructor/?query=${query}`)

        if(!searchQuery){
            searchQuery = `?query=${query}`
        }

        if(type){
            searchQuery += `&type=${type}`
        }


        searchQuery += `&page=${pageNumber}`
        searchQuery += `&size=${size}`



        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/${searchQuery}`, null);
        return response;
    }

    static async loadPeople(type="",query="") {

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/?type=${type}&size=200&query=${query}`, null);
        return response;
    }

    static async loadInstructor(id) {
        let language = Utils.getCurrentLanguageId()

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/${id}?language=${language}`, null);
        return response;
    }

    static async loadInstructorBySlug(slug) {

        let language = Utils.getCurrentLanguageId()

        var service = new HttpService();
        var response = await service.get(`/academy/course/instructor/slug/${slug}?language=${language}`, null);
        return response;
    }

    static async saveInstructor(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/instructor/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/instructor/', body);

        return await response
    }

    static async SetInstructorToCourse(course,instructor,action="add") {

        var service = new HttpService();
        var response;
        let body = {
            action:action
        }

        response = await service.post(`/academy/course/${course}/instructor/${instructor}/`, body);

        return response
    }

    static async loadReviews(course=null,size=30,content=null) {

        let query = `?size=${size}`;
        if(course){
            query += `&course=${course}`;
        }

        if(content){
            query += `&content=${content}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/review/${query}`, null);
        return response;
    }

    static async loadReviewsWithCourse(size=30,course=null) {

        let query = `?size=${size}`;
        var service = new HttpService();
        var response = await service.get(`/academy/course/review/list/${query}`, null);
        return response;
    }

    static async loadReview(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/review/${id}`, null);
        return response;
    }


    static async saveReview(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/review/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/review/', body);

        return await response
    }

    static async changeReviewStatus(review) {

        var service = new HttpService();
        var response;

        let body={
            id: review.id,
            course: review.course.id,
            status: !review.status
        }

        response = await service.put(`/academy/course/review/${body.id}`, body);

        return await response
    }


    static async loadEnrolments(pageNumber=1,courseId,filterQuery="") {
        var service = new HttpService();
        var query = `?all=1&page=${pageNumber}`;

        if(courseId != null){
            query += `&course=${courseId}`;
        }

        if(filterQuery) query+=`&${filterQuery}`;
        
        var response = await service.get(`/academy/course/enrollment/${query}`, null);
        return response;
    }

    static async loadUserEnrolments(userId) {

        var service = new HttpService();
        var response = await service.get(`/academy/course/user-enrollments/${userId}`, null);
        return response;
    }

    static async loadUserCourseSummary(courseId,userId) {

        var service = new HttpService();
        var response = await service.get(`/academy/course/${courseId}/summary?user=${userId}`, null);
        return response;
    }

    static async loadUserCourses(is_finished=null,course=null,size=30) {

        let query = `?size=${size}`;
        if(course){
            query += `&course=${course}`;
        }
        if(is_finished!=null){
            query += `&is_finished=${is_finished}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/enrollment/${query}`, null);
        return response;
    }

    static async loadTracks({size=30, content}) {

        let query = `?size=${size}`;

        let language = Utils.getCurrentLanguageId()

        if(language!= null){
            query = query + `&language=${language}`
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/content/${content}/track${query}`, null);
        return response;
    }

    static async loadTrack(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/track/${id}`, null);
        return response;
    }

    static async saveTrack(body) {
        const formData = new FormData();
        formData.append("content", body.content);
        formData.append("label", body.label);
        formData.append("src_lang", body.src_lang);
        formData.append("is_default", body.is_default);

        if(body.file){
            formData.append("src", body.file, body.file.name);
        }

        console.log(body)
        
        var service = new HttpService();
        var response;
        if (body.id) {
            response = await service.put(`/academy/course/track/${body.id}`,formData,true);
        }
        else
            response = await service.post(`/academy/course/content/${body.content}/track`,formData,true);
      
        return response;
    }


    static async loadQuizes({size=30, content , lesson}) {

        let query = `?size=${size}`;
        if(content){
            query += `&content=${content}`;
        }
        if(lesson){
            query += `&lesson=${lesson}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/quiz/${query}`, null);
        return response;
    }
    

    static async loadQuiz(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/quiz/${id}`, null);
        return response;
    }


    static async saveQuiz(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/quiz/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/quiz/', body);

        return await response
    }

    static async loadQuizQuestions(quiz=null,content=null,size=30) {

        let query = `?size=${size}`;
        if(quiz){
            query += `&quiz=${quiz}`;
        }
        if(content){
            query += `&content=${content}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/question/${query}`, null);
        return response;
    }

    static async loadQuizQuestion(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/question/${id}`, null);
        return response;
    }

    static async saveQuizQuestion(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/question/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/question/', body);

        return await response
    }


    static async saveContentChoice(question, choice) {

        var service = new HttpService();
        var response;

        let body ={
            "question": question,
            "choice": choice
        }

        response = await service.post('/academy/course/content/question/', body);

        return await response
    }

    static async saveQuizChoices(body) {

        var service = new HttpService();
        var response;

        response = await service.post('/academy/course/quiz/submit/', body);

        return await response
    }

    static async loadLrc(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/lrc/${id}`, null);
        return response;
    }

    static async loadAllLrcs(content=null) {

        let query = `?q`;
        if(!content) return [];
        if(content){
            query += `&content=${content}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/lrc/${query}`, null);
        return response;
    }
    static async loadLrcs(content=null) {

        let query = `?q`;
        if(!content) return [];
        if(content){
            query += `&content=${content}`;
        }

        let language = Utils.getCurrentLanguageId()

        if(language!= null){
            query = query + `&language=${language}`
        }

        

        var service = new HttpService();
        var response = await service.get(`/academy/course/lrc/${query}`, null);
        return response;
    }

    
    
    


    static async saveLrc(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/lrc/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/lrc/', body);

        return await response
    }


    static async loadTranscripts(content=null) {

        let query = `?q`;
        if(!content) return [];
        if(content){
            query += `&content=${content}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/transcript/${query}`, null);
        return response;
    }

    static async loadTranscript(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/transcript/${id}`, null);
        return response;
    }

    static async saveTranscript(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/transcript/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/transcript/', body);

        return await response
    }


    static async saveSortOrder(body) {

        var service = new HttpService();
        var response;

        response = await service.post('/academy/course/sort/', body);

        return await response
    }

    static async enroll(courseId) {

        let body = {
            course:courseId
        }

        var service = new HttpService();
        var response;

        response = await service.post('/academy/course/enroll/', body);

        return await response
    }

    static async loadQuizQuestionChoices(quizQuestionId=null,content=null,size=30) {

        let query = `?size=${size}`;
        if(quizQuestionId){
            query += `&quiz_question=${quizQuestionId}`;
        }

        var service = new HttpService();
        var response = await service.get(`/academy/course/question/choice/${query}`, null);
        return response;
    }

    static async loadQuizQuestionChoice(id) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/question/choice/${id}`, null);
        return response;
    }

    
    static async saveQuizQuestionChoice(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/academy/course/question/choice/${body.id}`, body);
        }
        else
            response = await service.post('/academy/course/question/choice/', body);

        return await response
    }

    static async findSlug(slug) {
        var service = new HttpService();
        var response = await service.get(`/academy/course/slug/?slug=${slug}`, null);
        return response;
    }



    
}



