import { HttpService } from "services/http";
import { Utils } from "utils";


export class PostHttpService {


    static async loadAuthor(id){
        var service = new HttpService();
        var response = await service.get(`/postapi/author/${id}`,null);
        return response;
    }

    static async saveAuthor(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/postapi/author/${body.id}`, body);
        }
        else
            response = await service.post('/postapi/author/', body);

        return await response
    }

    static async loadAuthors(){
        var query = "?size=100"
        var service = new HttpService();
        var response = await service.get(`/postapi/author/`,null);
        return response;
    }
    
    static async loadTags(){
        var query = "?size=100"
        var service = new HttpService();
        var response = await service.get(`/postapi/tag/`,null);
        return response;
    }

    static async saveCategorySortOrder(body) {

        var service = new HttpService();
        var response;

        response = await service.post(`/postapi/category/sort/`, body);

        return await response
    }

    static async saveCategory(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/postapi/category/${body.id}/`, body);
        }
        else
            response = await service.post('/postapi/category/', body);

        return await response
    }
    
    static async savePost(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/postapi/post/${body.id}/`, body);
        }
        else
            response = await service.post('/postapi/posts/', body);

        return await response
    }

    static async loadPost(id){
        var service = new HttpService();
        var response = await service.get(`/postapi/post/${id}`,null);
        return response;
    }

    static async loadPostBySlug(slug){
        var service = new HttpService();
        var response = await service.get(`/postapi/post/slug/${slug}`,null);
        return response;
    }
    
    static async loadPosts(categoryId,q,size=100,status=null,keyword="",exclude_category=null){
        var query = `?size=${size}`
        if(categoryId){
            query += "&category=" + categoryId
        }

        let language = Utils.getCurrentLanguageId()

        if(language!= null){
            query = query + `&language=${language}`
        }

        if(status!= null){
            query = query + `&status=${status}`
        }

        if(q){
            query += "&query=" + q
        }

        
        if(keyword){
            query += "&keyword=" + keyword
        }

        
        if(exclude_category){
            query += "&exclude_category=" + exclude_category
        }

        console.log(query);


        var service = new HttpService();
        var response = await service.get(`/postapi/posts/${query}`,null);
        return response;
    }

    static async loadArtistPosts(authorId,size=28){
        var query = `?size=${size}`
        if(authorId){
            query += "&author=" + authorId
        }

        let language = Utils.getCurrentLanguageId()

        if(language!= null){
            query = query + `&language=${language}`
        }

        query = query + `&status=1`

        var service = new HttpService();
        var response = await service.get(`/postapi/posts/${query}`,null);
        return response;
    }

    static async loadAllPosts(categoryId,q){
        var query = "?size=100"
        if(categoryId){
            query += "&category=" + categoryId
        }

        var service = new HttpService();
        var response = await service.get(`/postapi/posts/${query}`,null);
        return response;
    }

    static async searchPosts(query,pageNumber=1,size=50){
        
        query += "&size=" + size
        query += "&page=" + pageNumber

        console.log(query);

        var service = new HttpService();
        var response = await service.get(`/postapi/posts/${query}`,null);
        return response;
    }

    static async loadCategory(id){
        var service = new HttpService();
        var response = await service.get(`/postapi/category/${id}`,null);
        return response;
    }

    static async loadCategories(parent=null,contain_posts="0",status=null,isFeatured=null,menu=null,exclude_ids){
        var query="?size=30"
        query += `&posts=${contain_posts}`


        let language = Utils.getCurrentLanguageId()


        if(language!= null){
            query = query + `&language=${language}`
        }

        if(parent!= null){
            query = query + `&parent=${parent}`
        }

        if(status!= null){
            query = query + `&status=${status}`
        }

        if(isFeatured!= null){
            query = query + `&featured=${isFeatured}`
        }

        if(menu!= null){
            query = query + `&menu=${menu}`
        }

        if(exclude_ids){
            query = query + `&exclude_ids=${exclude_ids}`;
        }



        var service = new HttpService();
        var response = await service.get(`/postapi/category/${query}`,null);


        if(response?.detail){
            return null;
        }
        
        return response;
    }

    static async loadFeaturedCategories(){
        var query="?size=30"
        query += `&posts=1`


        query = query + `&featured=1`


        var service = new HttpService();
        var response = await service.get(`/postapi/category/${query}`,null);


        if(response?.detail){
            return null;
        }
        
        return response;
    }
   
}

