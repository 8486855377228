import React, { useContext, useEffect, useState } from 'react'
import { Utils, translate } from '../../../utils'
import PageLayout from 'view/components/shared/PageLayout'
import { LanguageContext } from 'contexts/LanguageContext'
import InstructorsGrid from 'view/components/course/components/InstructorsGrid'

const alphabet = Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i));

function MusicianPage() {

	const [query, setQuery] = useState()
	const [searchQuery, setSearchQuery] = useState()
    const [selectedStartLetter, setSelectedStartLetter] = useState(null)

	useEffect(() => {
		// Utils.initiateTheme()
	}, [])

	const handleStartLetterClick = (char) => {
        setQuery("")
        var queryString = `?size=60`;
        queryString += `&start_letter=${char}`;
        setSearchQuery(queryString);
    };

	
	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild  />}>
			<div className="mb-5">
                {/* <div className="dictionary-characters-container">
                    {alphabet.map((char) => (
                        <div key={char} className="character-box" onClick={() => { setSelectedStartLetter(char); handleStartLetterClick(char); }} >
                            {char}
                        </div>
                    ))}
                </div> */}
                <form action="" className='text-center' onSubmit={(e) => { e.preventDefault(); setSearchQuery(`?query=${query}`) }}>
                    <div className="search-container">
                        <div className="dictionary-search-container  input-group mt-2 mb-3 kfont">
                            <input className="form-control text-4 dictionary-search-input" value={query} name="q" onChange={(e) => {
								setQuery(e.target.value);
                                setSearchQuery(`?query=${e.target.value}`);
                            }} type="search" placeholder="Search..." />

                            <button className="btn" type="submit" aria-label="Search">
                                <i className="fas fa-search header-nav-top-icon"></i>
                            </button>
                           
                        </div>
                    </div>
                </form>
            </div>
			<InstructorsGrid searchQuery={searchQuery} type="musician" size={100} />
		</PageLayout>


	)
}


function HeaderChild() {
	const { translations } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)
	}

	return (
		<>
			<h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8 mb-3">{_t("Musicians")}</h1>
			
		</>

	)
}

export default MusicianPage